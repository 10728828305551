import React, { lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../auth';
import Template from '../template';

const Home = lazy(() => import('../home'));
const Login = lazy(() => import('../login'));
const Projects = lazy(() => import('../projects/Projects'));
const ProjectsCustomers = lazy(() => import('../projects/ProjectsCustomerList'));
const ProjectsInternal = lazy(() => import('../projects/ProjectsInternalList'));
const ProjectsDetail = lazy(() => import('../projects/ProjectsDetail'));
const CustomerProjectsDetail = lazy(() => import('../customerProjects/ProjectsDetail'));

const routes = [
    { exact: true, key: '/home', path: ['/', '/home'], component: Home, isPrivate: false },
    { exact: true, key: '/login', path: ['/login'], component: Login, isPrivate: false },
    { exact: true, key: '/projects', path: ['/projects'], component: Projects, isPrivate: true },
    { exact: true, key: '/internal-projects', path: ['/internal-projects'], component: ProjectsInternal, isPrivate: true },
    { exact: true, key: '/customer-projects', path: ['/customer-projects'], component: ProjectsCustomers, isPrivate: true },
    {
        exact: true,
        key: '/projects/:id',
        path: ['/projects/:id'],
        component: ProjectsDetail,
        isPrivate: true,
    },
    {
        exact: true,
        key: '/customer-projects/:id',
        path: ['/customer-projects/:id'],
        component: CustomerProjectsDetail,
        isPrivate: true,
    },
];

const ControlledRoute = ({ component: Component, ...rest }) => {
    const { isAdmin, isCustomer, isInternalUser } = useAuth();
    const { isPrivate } = rest;

    return (
        <Route
            {...rest}
            render={(props) => (
                <Template>
                    {!isPrivate ? (
                        <Component {...props} />
                    ) : isAdmin() || isCustomer() || isInternalUser() ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: {
                                    from: props.location,
                                    unauthorized: true,
                                    message: 'Você não possui permissão para acessar esta página.',
                                },
                            }}
                        />
                    )}
                </Template>
            )}
        />
    );
};

export default routes.map((route) => <ControlledRoute {...route} />);
