import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import axios from 'axios';
import './Auth.scss';
import jwtDecode from 'jwt-decode';
import numeral from 'numeral';
import 'numeral/locales/pt-br';

numeral.locale('pt-br');

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const localStorageKey = '__focco-crowdfounding__';
const AuthContext = React.createContext();

const ROLES = {
    InternalUser: 'InternalUser',
    Admin: 'Admin',
    Customer: 'Customer',
};

const AuthProvider = (props) => {
    const history = useHistory();
    let authInitialData = {
        username: null,
        email: null,
        name: null,
        roles: null,
    };

    let storage = window.localStorage.getItem(localStorageKey);

    if (storage) {
        const { token, username, email, name } = JSON.parse(storage);

        const { role } = jwtDecode(token);

        api.defaults.headers.common.Authorization = `Bearer ${token}`;

        authInitialData = {
            username,
            email,
            name,
            roles: role,
        };
    }

    const [user, setUser] = useState(authInitialData);
    const [loading, setLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(storage || false);

    /*removido o set do userId, pois o ID do usuário não pode ser um nome ou email conforme as diretrizes da google
    useEffect(() => {
        ReactGA.set({userId: ''});
    }, [user]);
    */

    const isAdmin = () => {
        if (!user.roles || !authenticated) return false;

        return (
            (typeof user.roles === 'string' && user.roles === ROLES.Admin) ||
            (Array.isArray(user.roles) && user.roles.includes(ROLES.Admin))
        );
    };

    const isCustomer = () => {
        if (!user.roles || !authenticated) return false;

        return (
            (typeof user.roles === 'string' && user.roles === ROLES.Customer) ||
            (Array.isArray(user.roles) && user.roles.includes(ROLES.Customer))
        );
    };

    const isInternalUser = () => {
        if (!user.roles || !authenticated) return false;

        return (
            (typeof user.roles === 'string' && user.roles === ROLES.InternalUser) ||
            (Array.isArray(user.roles) && user.roles.includes(ROLES.InternalUser))
        );
    };

    const login = async (formUsername, formPassword) => {
        let response;
        setLoading(true);

        try {
            const { data } = await api.post('login', {
                Username: formUsername,
                Password: formPassword,
            });

            const { succeeded } = data;

            if (succeeded) {
                const { token, username, email, name, roles } = data.value;

                api.defaults.headers.common.Authorization = `Bearer ${token}`;

                window.localStorage.setItem(
                    localStorageKey,
                    JSON.stringify({
                        token,
                        username,
                        email,
                        name,
                    })
                );

                setAuthenticated(true);

                setUser({
                    username,
                    email,
                    name,
                    roles,
                });
            }

            response = data;
            history.push('/projects');
        } catch (error) {
            const { data } = error.response;
            response = data;
        }

        setLoading(false);
        return response;
    };

    const logout = async () => {
        setLoading(true);
        window.localStorage.removeItem(localStorageKey);

        delete api.defaults.headers.common.Authorization;

        setAuthenticated(false);
        setLoading(false);

        history.push('/login');
    };

    api.interceptors.response.use(null, (error) => {
        if (error.response.status === 401) logout();
        return Promise.reject(error);
    });

    const authClass = 'auth';
    const authLoadingClass = authClass + '__loading';

    return (
        <AuthContext.Provider
            value={{ api, authenticated, isAdmin, isCustomer, isInternalUser, loading, login, logout, user, numeral }}
            {...props}>
            <React.Fragment>
                <CSSTransition in={loading} timeout={150} unmountOnExit classNames={authLoadingClass}>
                    <div className={authLoadingClass}>
                        <div className="loader" />
                        <div>Carregando</div>
                    </div>
                </CSSTransition>
                {props.children}
            </React.Fragment>
        </AuthContext.Provider>
    );
};

const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) throw new Error(`useAuth deve ser utilizado com um AuthProvider`);
    return context;
};

export { AuthProvider as default, useAuth };
