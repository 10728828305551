import React from 'react';
import './Template.scss';
import TemplateProvider from './Context';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';

const Template = ({ children }) => {
    const templateClass = 'site';
    const templateContentClass = templateClass + '__content';

    return (
        <TemplateProvider>
            <div className={templateClass}>
                <Header />
                <div className={templateContentClass}>{children}</div>
                <Footer />
            </div>
        </TemplateProvider>
    );
};

export default Template;
