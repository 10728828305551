import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth';
import Button from '../../components/button';
import AuthenticatedUser from '../../components/header/AuthenticatedUser';
import './Template.scss';

const Template = ({ children }) => {
    const { logout } = useAuth();
    const templateClass = 'member';
    const templateContentClass = templateClass + '__content';
    const headerClass = 'member__header';
    const headerContent = classnames(headerClass + '-content', 'common-container');
    const headerTitle = headerClass + '-title';
    const headerMenu = headerClass + '-menu';
    const headerMenuItem = headerMenu + '-item';

    return (
        <div className={templateClass}>
            <div className={headerClass}>
                <div className={headerContent}>
                    <div className={headerTitle}>
                        <h1>
                            <span>Impulso</span>
                            Painel de Administração
                        </h1>
                    </div>
                    <AuthenticatedUser />
                    <div className={headerMenu}>
                        <ul>
                            <li className={headerMenuItem}>
                                <Link to="/member/projects">Seus Projetos</Link>
                            </li>
                            <li className={headerMenuItem}>|</li>
                            <li className={headerMenuItem}>
                                <Link to="/">Voltar ao site</Link>
                            </li>
                            <li className={headerMenuItem}>
                                <Button onClick={logout} outlined>
                                    Sair
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={templateContentClass}>{children}</div>
        </div>
    );
};

export default Template;
