import classnames from 'classnames';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { useAuth } from '../../../auth';
import Button from '../../../components/button';
import AuthenticatedUser from '../../../components/header/AuthenticatedUser';
import { useTemplate } from '../../template/Context';
import './Header.scss';

smoothscroll.polyfill();

const Header = () => {
    const { authenticated, isAdmin, logout } = useAuth();
    const { headerOffset, howItWorksTop, scrollTop, scrollWindow } = useTemplate();
    const { pathname } = useLocation();
    const history = useHistory();

    const scrollTopToHowItWorks = () => {
        const topOffset = howItWorksTop - headerOffset;

        if (pathname !== '/' && pathname !== '/home') {
            history.push('/');
            setTimeout(() => scrollWindow(topOffset), 200);
        } else {
            scrollWindow(topOffset);
        }
    };

    const isWindowScrolled = () => {
        return scrollTop > 20;
    };

    const headerClass = 'header';
    const headerClasses = classnames(headerClass, {
        [headerClass + '--scrolled']: isWindowScrolled(),
    });

    const adminBarClass = headerClass + '__admin-bar';

    const containerClass = headerClass + '__container';
    const containerClasses = classnames(containerClass, 'common-container', {
        [containerClass + '--scrolled']: isWindowScrolled(),
        [containerClass + '--admin']: isAdmin(),
    });

    const logoClass = headerClass + '__logo';
    const logoDefault = process.env.PUBLIC_URL + '/images/Logo_Focco_Cyncly_branco.png';
    const logoScrolled = process.env.PUBLIC_URL + '/images/Logo_Focco_Cyncly.png';

    const taglineClass = headerClass + '__tagline';
    const taglineClasses = classnames(taglineClass, {
        [taglineClass + '--scrolled']: isWindowScrolled(),
    });

    const menuClass = headerClass + '__menu';
    const menuItemClass = menuClass + '-item';
    const menuItemDropDownClass = menuItemClass + '-dropdown';
    const menuItemDropDownClasses = classnames(menuItemDropDownClass, {
        [menuItemDropDownClass + '--scrolled']: isWindowScrolled(),
    });

    const menuItemButtonClasses = classnames('common-button-text', {
        'common-button-text--blue': isWindowScrolled(),
        'common-button-text--white': !isWindowScrolled(),
    });

    return (
        <div className={headerClasses}>
            {authenticated && (
                <div className={adminBarClass}>
                    <div className="common-container">
                        {isAdmin() ? (
                            <Link to="/admin">Painel de Administração</Link>
                        ) : (
                            <React.Fragment>
                                <Link to="/member/projects">Seus Projetos</Link>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            )}
            <div className={containerClasses}>
                <div className={logoClass}>
                    <Link to="/">
                        <img
                            height="45"
                            src={isWindowScrolled() ? logoScrolled : logoDefault}
                            alt="Focco Soluções de Gestão"
                        />
                    </Link>
                </div>
                <div className={taglineClasses}>Impulso</div>
                <div className={menuClass}>
                    {authenticated && <AuthenticatedUser />}
                    <ul>
                        <li className={menuItemClass}>
                            <button className={menuItemButtonClasses} onClick={scrollTopToHowItWorks}>
                                Conheça
                            </button>
                        </li>
                        {authenticated && (
                            <React.Fragment>
                                <li className={menuItemClass}>
                                    <Link to="/projects">
                                        <button className={menuItemButtonClasses}>Projetos</button>
                                    </Link>
                                </li>
                                <li className={menuItemClass}>
                                    <Button color={isWindowScrolled() ? 'blue' : 'white'} onClick={logout} outlined>
                                        Sair
                                    </Button>
                                </li>
                            </React.Fragment>
                        )}
                        {!authenticated && (
                            <li className={menuItemClass}>
                                <React.Fragment>
                                    <Link to="/login">
                                        <Button color={isWindowScrolled() ? 'blue' : 'white'} outlined>
                                            Entrar
                                        </Button>
                                    </Link>
                                    <div className={menuItemDropDownClasses}>
                                        <span>Conecte-se</span> através de
                                        <br />
                                        sua conta do portal WP para
                                        <br />
                                        visualizar os projetos
                                    </div>
                                </React.Fragment>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;
