import React from 'react';
import './Footer.scss';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const { pathname } = useLocation();

    const footerClass = 'footer';
    const footerClasses = classnames(footerClass, {
        [footerClass + '--top-bordered']: pathname !== '/' || pathname !== '/home'
    });

    const footerBottomClass = footerClass + '__bottom';
    const footerBottomCopyClass = footerBottomClass + '-copy';
    const footerBottomLinksClass = footerBottomClass + '-links';

    return (
        <div className={footerClasses}>
            <div className={footerBottomClass}>
                <div className="common-container">
                    <div className={footerBottomCopyClass}>
                        Copyright {new Date().getFullYear()} © <span>Focco Soluções de Gestão</span>. Todos os direitos
                        reservados.
                    </div>
                    <div className={footerBottomLinksClass}>
                        <ul>
                            <li>
                                <a href="mailto: impulso@focco.com.br">Fale Conosco</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
