import React, { Suspense } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import AuthProvider from './auth';
import SiteRoutes from './site/routes';
import MemberRoutes from './member/routes';
import AdminRoutes from './admin/routes';
import ReactGA from 'react-ga';
import Error404 from './errors/404';

const App = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_KEY);
    const history = useHistory();

    history.listen((location) => {
        document.title = 'Impulso';
        ReactGA.set({ page: location.pathname });
        ReactGA.pageview(location.pathname);
    });

    return (
        <AuthProvider>
            <Suspense fallback={null}>
                <Switch>
                    {SiteRoutes}
                    {MemberRoutes}
                    {AdminRoutes}
                    <Route path="*" component={Error404} />
                </Switch>
            </Suspense>
        </AuthProvider>
    );
};

export default App;
