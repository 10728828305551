import React from 'react';
import { Icon } from 'antd';
import { useAuth } from '../../auth';
import './AuthenticatedUser.scss';

const AuthenticatedUser = () => {
    const { user, authenticated, isCustomer } = useAuth();

    const authUserClass = 'authenticated-user';
    const authUserContentClass = authUserClass + '__content';
    const authUserContentHightlightClass = authUserContentClass + '--highlight';

    return (
        authenticated && (
            <div className={authUserClass}>
                {isCustomer()}
                <div className={authUserContentClass}>
                    <div>Seja bem-vindo(a)</div>
                    <div className={authUserContentHightlightClass}>{user.name}</div>
                </div>
                <div className={authUserContentClass}>
                    <Icon type="right" />
                </div>
            </div>
        )
    );
};

export default AuthenticatedUser;
