import React, { lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../auth';
import Template from '../template';

const CustomerProjectsManagement = lazy(() => import('../customerProjects/ProjectsManagement'));
const Customers = lazy(() => import('../customers'));
const CustomersView = lazy(() => import('../customers/CustomersView'));
const Parameters = lazy(() => import('../parameters'));
const Projects = lazy(() => import('../projects'));
const ProjectsManagement = lazy(() => import('../projects/ProjectsManagement'));
const ProjectsView = lazy(() => import('../projects/ProjectsView'));

const routes = [
    {
        exact: true,
        key: '/admin/customer-projects/management',
        path: ['/admin/customer-projects/management/:id'],
        component: CustomerProjectsManagement,
    },
    { exact: true, key: '/admin/customers', path: ['/admin/customers'], component: Customers },
    { exact: true, key: '/admin/customers/view', path: '/admin/customers/view/:emprId', component: CustomersView },
    { exact: true, key: '/admin/parameters', path: ['/admin/parameters'], component: Parameters },
    { exact: true, key: '/admin/projects', path: ['/admin', '/admin/projects'], component: Projects },
    {
        exact: true,
        key: '/admin/projects/management',
        path: ['/admin/projects/management', '/admin/projects/management/:id'],
        component: ProjectsManagement,
    },
    { exact: true, key: '/admin/projects/view', path: '/admin/projects/view/:id', component: ProjectsView },
];

const ControlledRoute = ({ component: Component, ...rest }) => {
    const { isAdmin } = useAuth();

    return (
        <Route
            {...rest}
            render={(props) => (
                <Template>
                    {isAdmin() ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: {
                                    from: props.location,
                                    unauthorized: true,
                                    message: 'Você não possui permissão para acessar esta página.',
                                },
                            }}
                        />
                    )}
                </Template>
            )}
        />
    );
};

export default routes.map((route) => <ControlledRoute {...route} />);
