import React from 'react';
import './Error404.scss';

const Error404 = () => {
    const errorClass = 'error-404';

    return (
        <div className={errorClass}>
            <div>404</div>
            <div>
                <span>Página</span>
                <span>não</span>
                <span>encontrada</span>
            </div>
        </div>
    );
};

export default Error404;
