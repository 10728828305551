import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../auth';
import Button from '../../components/button';
import './Template.scss';

const Template = ({ children }) => {
    const { logout } = useAuth();
    const templateClass = 'admin';
    const templateContentClass = templateClass + '__content';
    const headerClass = 'admin__header';
    const headerTitle = headerClass + '-title';
    const headerMenu = headerClass + '-menu';
    const headerMenuItem = headerMenu + '-item';

    return (
        <div className={templateClass}>
            <div className={headerClass}>
                <div className={headerTitle}>
                    <h1>
                        <span>Impulso</span>
                        Painel de Administração
                    </h1>
                </div>
                <div className={headerMenu}>
                    <ul>
                        <li className={headerMenuItem}>
                            <Link to="/admin/customers">Clientes</Link>
                        </li>
                        <li className={headerMenuItem}>|</li>
                        <li className={headerMenuItem}>
                            <Link to="/admin/parameters">Parâmetros</Link>
                        </li>
                        <li className={headerMenuItem}>|</li>
                        <li className={headerMenuItem}>
                            <Link to="/admin/projects">Projetos</Link>
                        </li>
                        <li className={headerMenuItem}>|</li>
                        <li className={headerMenuItem}>
                            <Link to="/">Voltar ao site</Link>
                        </li>
                        <li className={headerMenuItem}>
                            <Button onClick={logout} outlined>
                                Sair
                            </Button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={templateContentClass}>{children}</div>
        </div>
    );
};

export default Template;
