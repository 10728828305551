import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './Button.scss';

class Button extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        color: PropTypes.oneOf(['blue', 'orange', 'white']),
        onClick: PropTypes.func,
        outlined: PropTypes.bool,
        flat: PropTypes.bool,
        size: PropTypes.oneOf(['xs', 'sm','md', 'lg', 'xl']),
        type: PropTypes.oneOf(['button', 'submit'])
    };

    static defaultProps = {
        outlined: false,
        color: 'orange',
        size: 'md',
        type: 'button'
    };

    class = 'button';

    render() {
        const { children, className, color, onClick, outlined, size, type, flat } = this.props;

        const buttonClasses = classnames(this.class, this.class + '--' + size, this.class + '--' + color, className, {
            [this.class + '--outlined']: outlined,
            [this.class + '--flat']: flat
        });

        const buttonContentClass = this.class + '__content';

        return (
            <button className={buttonClasses} type={type} onClick={onClick}>
                <span className={buttonContentClass}>{children}</span>
            </button>
        );
    }
}

export default Button;
