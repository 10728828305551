import React, { lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../auth';
import Template from '../template';

const Projects = lazy(() => import('../projects'));
const ProjectsManagement = lazy(() => import('../projects/ProjectsManagement'));
const PointsExtract = lazy(() => import('../projects/PointsExtract'));

const routes = [
    { exact: true, key: '/member/projects', path: ['/member', '/member/projects'], component: Projects },
    { exact: true, key: '/member/points-extract', path: ['/member', '/member/points-extract'], component: PointsExtract },
    {
        exact: true,
        key: '/member/projects/management',
        path: ['/member/projects/management', '/member/projects/management/:id'],
        component: ProjectsManagement,
    },
];

const ControlledRoute = ({ component: Component, ...rest }) => {
    const { isCustomer } = useAuth();

    return (
        <Route
            {...rest}
            render={(props) => (
                <Template>
                    {isCustomer() ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: {
                                    from: props.location,
                                    unauthorized: true,
                                    message: 'Você não possui permissão para acessar esta página.',
                                },
                            }}
                        />
                    )}
                </Template>
            )}
        />
    );
};

export default routes.map((route) => <ControlledRoute {...route} />);
