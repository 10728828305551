import React, { useCallback, useEffect, useState } from 'react';

const TemplateContext = React.createContext();

const TemplateProvider = (props) => {
    const [scrollTop, setScrollTop] = useState(0);
    const [howItWorksTop, setHowItWorksTop] = useState(0);

    const headerOffset = 90;

    const scrollWindow = (top) => {
        window.scroll({
            top: top,
            left: 0,
            behavior: 'smooth'
        });
    };

    const handleWindowScroll = useCallback(() => {
        setScrollTop(window.scrollY);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleWindowScroll);

        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, [handleWindowScroll]);

    return (
        <TemplateContext.Provider
            value={{ headerOffset, howItWorksTop, scrollTop, scrollWindow, setHowItWorksTop }}
            {...props}
        />
    );
};

const useTemplate = () => {
    const context = React.useContext(TemplateContext);
    if (context === undefined) throw new Error(`useTemplate deve ser utilizado com um Template`);
    return context;
};

export { TemplateProvider as default, useTemplate };
